import ScrollToTop from "./base-components/ScrollToTop";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import "./assets/css/app.css";
import React, { useEffect } from "react";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  
  useEffect(() => {
    const collapseValue = localStorage.getItem("Collapse");

    if (collapseValue === null || parseInt(collapseValue) === 0) {
      localStorage.removeItem("Collapse");
      document.body.classList.remove("nocollapse");
    } else {
      document.body.classList.add("nocollapse");
    }

    const collapseValueFolder = localStorage.getItem("FolderCollapse");
    if (collapseValueFolder === null || parseInt(collapseValueFolder) === 0) {
      localStorage.removeItem("FolderCollapse");
      document.body.classList.remove("foldercollapse");
    } else {
      document.body.classList.add("foldercollapse");
    }


    const DarkValue = localStorage.getItem("DarkMode");
    // console.log(DarkValue);
    if (DarkValue === null || parseInt(DarkValue) === 0) {
      localStorage.removeItem("DarkMode");
      document.body.classList.remove("darkmind");
    } else {
      document.body.classList.add("darkmind");
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId="431601297091-rn26euiflghbbp5tr3me9bt6aknha4p4.apps.googleusercontent.com">
      <BrowserRouter>
        <Router />
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          className="toast_class"
        />
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

root.render(<App />);
