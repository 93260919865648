export const urls = {
  // API: "http://192.168.1.10/ProcX-Backend/",
  // PHP_API: "http://192.168.1.10/ProcX-Backend/",

  API: "https://procx.co/admin/",
  PHP_API: "https://procx.co/admin/",

  // API: "https://backendapi.dedevelopers.org/admin/",
  // PHP_API: "https://backendapi.dedevelopers.org/admin/",

  // API: "http://192.168.1.31/Codeigniter/ProcX/admin/",
  // PHP_API: "http://192.168.1.31/Codeigniter/ProcX/admin/",
};
